import React, { useState } from 'react';
import {
  Container, Card, Grid, Stepper, Step, StepLabel, Button as ButtonMUI,
  Typography, TextField, FormControl, RadioGroup, FormControlLabel,
  Radio, Accordion, AccordionDetails, AccordionSummary, CardMedia, Modal, Box,
} from "@mui/material";
import env from 'configs/vars';
import convertToRupiah from 'utils/formatCurrency';

import { CartItem, Leaflet, Loading, } from 'components';
import { Button, } from "components/atoms";

import Success from 'components/atoms/Success';


import { useDispatch, useSelector } from 'react-redux';
import { Fade } from "react-reveal";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PaymentsIcon from '@mui/icons-material/Payments';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { createUser, getUser } from 'store/actions/user';
import { clearCart } from 'store/actions/cart';
import { submitBooking } from 'store/actions/checkout';
import { toastProperties } from 'utils/toastProperties';
import { toast } from 'react-toastify';
import PencilLoader from 'components/atoms/PencilLoader';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "80%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const Cart = ({ data, cart }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isProcess, setIsProcess] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [responseSuccess, setResponseSuccess] = useState();
  const [postData, setPostData] = useState({ selectedFile: '', bankFrom: '' });
  const [reviewImage, setReviewImage] = useState("");
  const [showUpload, setShowUpload] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const dispatch = useDispatch();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('sb-wnpukijoybwfgrpearge-auth-token')));
  const [isSubmit, setIsSubmit] = useState(false);

  const { dataUser } = useSelector((state) => state.userReducer);
  const { stateLocation } = useSelector((state) => state.auth);

  const filteredData = data?.filter(({ items }) =>
    items?.find((item) => cart.find((el) => el.id === item.id))
  );

  console.log("filteredData=======>>>", filteredData)
  const totalOrder = cart.reduce((accumulator, item) => accumulator + item.subtotal, 0)
  const totalProduct = cart.length;


  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleNext = (submit) => {
    if (submit === "submit") {
      const payload = {
        userId: user.user.id,
        email: user.user.email,
        cartItems: cart,
        bankFrom: postData.bankName,
        selectedFile: postData.selectedFile,
      };

      setIsSuccess(false);
      setIsProcess(true);
      setIsSubmit(true);

      dispatch(submitBooking(payload))
        .then((res) => {
          setIsSuccess(false);
          setIsProcess(false);
          setResponseSuccess(res.data);
          setIsSuccess(true);
          setActiveStep(activeStep + 1);
          clear();
          dispatch(clearCart());
        })
        .catch((error) => {
          console.log("error submitBooking==", error);
        });
    } else if (submit === "checkout") {
      dispatch(getUser(user.user.email))
        .then((userData) => {
          if (userData.account === null || userData === undefined) setOpenModal(true);
          setActiveStep(activeStep + 1);
        })
        .catch((error) => {
          console.log("Error while getting user data:", error);
          setActiveStep(activeStep + 1);
        });
    } else {
      setActiveStep(activeStep + 1);
    }
  };


  const handleCloseModal = () => setOpenModal(false);

  const handleSubmit = () => {
    const initialState = {
      userId: user?.user?.id,
      name: user?.user?.user_metadata?.full_name,
      email: user?.user?.email,
      coordinatUser: stateLocation,
    };
    dispatch(createUser(initialState));
    handleCloseModal();
  }

  const handleReset = () => {
    setActiveStep(0);
    setIsSuccess(false);
  };
  const steps = ["Cart", "Checkout", "Successful"];

  const clear = () => {
    setPostData({ selectedFile: '' });
    setReviewImage("")
    setShowUpload(true)
  };

  // handle change form
  const onChange = (e) => {
    if (e.target.value === "COD") {
      setShowUpload(false)
    } else {
      setShowUpload(true)
    }
    setPostData({ ...postData, [e.target.name]: e.target.value });
  };

  const onChangeFile = async (e) => {
    const file = e.target.files[0];
    setReviewImage(file);
    setPostData({ ...postData, [e.target.name]: file });
  };


  return (
    <Container>
      <Card sx={{ borderRadius: "0px 0px 0px 0px", padding: 2, mt: 1 }}>
        <Grid item xs={12}>
          <Grid item xs={12} sx={{ mt: 1, mb: 6 }}>
            <Stepper activeStep={activeStep}>
              {steps?.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          {activeStep === 0 && (
            <>
              {filteredData?.map((item, index) => (
                <Grid item xs={12} key={index}>
                  {item.items.map((el) => (
                    <div key={el.id}>
                      {cart.map((cartItem) => {
                        if (cartItem.id === el.id) {
                          return (
                            <CartItem
                              image={`${env.bucketItem}/item/${el.images.image_url} `}
                              title={cartItem.title}
                              price={el.price}
                              unit={el.unit}
                              id={cartItem.id}
                              quantity={cartItem.qty}
                              status={0}
                            />
                          );
                        }
                        return null;
                      })}
                    </div>
                  ))}
                </Grid>
              ))}
              <Grid item xs={12} sx={{ mt: 2, }}>
                {user?.user ? (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={filteredData?.length === 0} title={"Checkout"}
                    onClick={() => handleNext("checkout")} />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled title={"Checkout"}
                    onClick={() => handleNext("checkout")} />
                )}
              </Grid>
            </>
          )}
          {activeStep === 1 && (
            <>
              {filteredData?.map((item, index) => (
                <Grid item xs={12} key={index}>
                  {item.items.map((el) => (
                    <div key={el.id}>
                      {cart.map((cartItem) => {
                        if (cartItem.id === el.id) {
                          return (
                            <CartItem
                              image={`${env.bucketItem}/item/${el.images.image_url} `}
                              title={cartItem.title}
                              price={el.price}
                              unit={el.unit}
                              id={cartItem.id}
                              quantity={cartItem.qty}
                              status={1}
                            />
                          );
                        }
                        return null;
                      })}
                    </div>
                  ))}
                </Grid>
              ))}
              <Grid container sx={{ mt: 2 }}>
                <Grid item xs={7} >
                </Grid>
                <Grid item >
                  <Typography variant="body2" color="div">Total Pesanan ({totalProduct} Item) : </Typography>
                </Grid>
                <Grid item sx={{ textAlign: "right", }}>
                  <Typography sx={{ ml: 2 }} variant="body2" color="secondary">{convertToRupiah(totalOrder)} </Typography>
                </Grid>

              </Grid>

              {/* pembayaran */}
              <Grid item xs={12} sx={{ mt: 3, mb: 2 }}>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <Typography variant="body2" color="div" align='right'>Pembayaran</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item xs={6}>
                        <FormControl component="fieldset" >
                          <Typography variant='body2' sx={{ mb: 1 }}>Pilih Pembayaran</Typography>
                          <RadioGroup >
                            {[
                              {
                                id: 2,
                                name: "BRI",
                                logo: `${env.bucketItem}/banks/logo-bri.png`,
                                rek: "1111-01-XXXXXXX"
                              },
                              {
                                id: 3,
                                name: "MANDIRI",
                                logo: `${env.bucketItem}/banks/logo-mandiri.jpg`,
                                rek: "2222-02-XXXXXXX"
                              },
                              {
                                id: 4,
                                name: "BCA",
                                logo: `${env.bucketItem}/banks/logo-bca.jpg`,
                                rek: "3333-03-XXXXXXX"
                              }
                            ].map(bank => (
                              <Grid container key={bank.id} style={{ paddingTop: 2 }}>
                                <Grid item xs={3} >
                                  <FormControlLabel
                                    name="bankName"
                                    value={bank.name}
                                    control={<Radio />}
                                    onChange={onChange}
                                  />
                                </Grid>
                                <Grid item>
                                  {bank.logo ?
                                    <img src={`${bank.logo}`} width="40" />
                                    : <PaymentsIcon fontSize="small" color="primary" />
                                  }
                                  <Typography variant='body2' >{bank.rek}</Typography>
                                </Grid>
                              </Grid>
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      {showUpload && (
                        <Grid item xs={6} >
                          <Typography variant='body2' >Upload bukti pembayaran</Typography>
                          <ButtonMUI
                            sx={{
                              zIndex: 1,
                              marginTop: "-1px",
                              paddingTop: "8px",
                              paddingBottom: "9px",
                            }}
                            variant="contained"
                            component="label"
                            size="small"
                            fontSize="small"
                            style={{ marginTop: 10 }}
                            startIcon={<CloudUploadIcon />}
                          >
                            Browse
                            <input
                              type="file"
                              name="selectedFile"
                              hidden
                              onChange={onChangeFile}
                              style={{ marginTop: 10 }}
                            />
                          </ButtonMUI>
                          {/* <TextField
                            inputProps={{ style: { textAlign: "center" } }}
                            disabled
                            fontSize="small"
                            size="small"
                            sx={{ border: "none", mb: 2, }}
                            onChange={onChangeFile}
                          /> */}
                          <Grid item xs={12}>
                            {reviewImage && (
                              <Card sx={{ minWidth: 100, maxWidth: 150, maxHeight: 150, padding: 1, boxShadow: 5 }} >
                                <CardMedia
                                  component="img"
                                  image={URL.createObjectURL(reviewImage)}
                                  width={300}
                                  style={{ borderRadius: 10, }} />
                              </Card>
                            )}
                          </Grid>
                        </Grid>

                      )}

                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              {/* end pembayaran */}
              <Button
                variant="contained"
                color="primary" title={"Back"}
                onClick={() => handleBack()} />
              <Button
                variant="contained"
                disabled={isSubmit}
                color="primary" sx={{ ml: 2 }} title={"Order Now"}
                onClick={() => handleNext("submit")} />
            </>
          )}
          {activeStep === 2 && (
            <>
              {isSuccess ? (
                <>
                  <Fade>
                    <Success />
                    <Typography sx={{ mb: 3, }}
                      align="center"
                      variant="body2" color="#128f3e">Terima kasih pesanan anda sedang di proses
                    </Typography>
                  </Fade>
                  <Button variant="contained" color="primary" onClick={handleReset} title={"Back"} />
                </>
              ) : (
                <PencilLoader />
              )}
            </>
          )}
        </Grid>
      </Card>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="subtitle1" component="h2">
            Tentukan lokasi anda
          </Typography>
          <Leaflet />
          <Button onClick={handleSubmit} variant="contained">Pilih</Button>
        </Box>
      </Modal>

    </Container >
  );
};

export default Cart;
