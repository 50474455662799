import React, { useEffect, useState, } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, } from "react-toastify";
import { Card, Container, Grid, } from "@mui/material";

import { useParams } from 'react-router-dom';
import { getItem } from "store/actions/item";
import { CardItem, Comments, Header, Loading, SpeedDial } from "components";
import env from "configs/vars";
import { getUser } from "store/actions/user";
import PencilLoader from "components/atoms/PencilLoader";


const ItemDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [user, setUser] = useState(JSON.parse(localStorage.getItem('sb-wnpukijoybwfgrpearge-auth-token')));

  const { cartItems } = useSelector((state) => state.cartReducer);
  const { dataLandingPageReducerAll } = useSelector((state) => state.landingPageReducer);

  const { dataItemReducer } = useSelector((state) => state.itemReducer);
  const { dataUser } = useSelector((state) => state.userReducer);

  useEffect(() => {
    dispatch(getItem(id));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUser(user?.user?.email))
  }, [dispatch])


  return (
    <Container spacing={2}>
      <Header cart={cartItems} data={dataLandingPageReducerAll.category} />
      <ToastContainer autoClose={8000} />
      <Card sx={{ mt: 2, p: 2 }}>
        <Grid container>
          {
            dataItemReducer && dataItemReducer.item ? (
              <>
                <Grid item xs={3}>
                  <CardItem
                    key={dataItemReducer.item.id}
                    id={dataItemReducer.item.id}
                    image={
                      dataItemReducer.item?.images?.image_url
                        ? `${env.bucketItem}/item/${dataItemReducer.item?.images?.image_url}`
                        : ""
                    }
                    title={dataItemReducer.item.title}
                    price={dataItemReducer.item.price}
                    unit={dataItemReducer.item.unit}
                  />
                </Grid>
                <Grid item xs={9}>
                  <Comments data={dataItemReducer} dataUser={dataUser} />
                </Grid>
              </>
            ) : (
              <PencilLoader />
            )
          }
        </Grid>
      </Card>
      {/* <SpeedDial /> */}
    </Container>
  );
}

export default ItemDetail