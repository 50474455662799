import React, { useEffect, useState } from "react";
import { Box, Typography, } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { editUser, getUser, } from "store/actions/user";

export default function UserLocation() {
    const { stateLocation } = useSelector((state) => state.auth);

    const initialState = {
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        coordinatUser: stateLocation,
        file: "",
    };
    const dispatch = useDispatch();
    const user = useState(JSON.parse(localStorage.getItem('sb-wnpukijoybwfgrpearge-auth-token')));
    const email = user[0]?.user?.email;

    const { dataUser, } = useSelector((state) => state.userReducer);
    const [form, setForm] = useState(initialState);


    useEffect(() => {
        if (email) dispatch(getUser(email));
    }, [email])

    useEffect(() => {
        if (dataUser) {
            setForm(dataUser.account);
        }
    }, [dataUser]);


    useEffect(() => {
        const handleSubmitAuto = () => {
            let inputValue = {
                email: form.email,
                coordinatUser: stateLocation,
                senderFrom: "Send by Web"
            };
            dispatch(editUser(inputValue));
        };

        handleSubmitAuto();
    }, [stateLocation]);


    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <Typography component="h1" variant="subtitle1" color="primary">
                {form?.name}
            </Typography>
        </Box>
    );
}